<template>
  <div class="container mt-6">
    <div class="mt-6">
      <div
        class="flex flex-col md:flex-row items-stretch md:items-end lg:items-center mb-4 md:mb-0 md:space-x-6"
      >
        <div
          class="flex items-stretch lg:items-center md:w-8/12 lg:w-9/12 order-2 md:order-1 flex-col lg:flex-row"
        >
          <h1 class="pc-page-title hidden md:block flex-1">
            {{ $t('order_history') }}
          </h1>
          <div
            class="flex space-x-2 items-center w-full md:w-auto md:justify-between lg:justify-start md:mt-4 lg:mt-0"
          >
            <date-picker class="flex-1 md:flex-none" :label="$t('date_range')" />
            <order-filters />
          </div>
        </div>

        <div class="relative md:w-4/12 lg:w-3/12 order-1 md:order-2 mb-4 md:mb-0">
          <input
            class="w-full search border border-solid border-grey-40 bg-white px-4 py-2 focus:outline-none box-border rounded-sm placeholder-grey-50"
            data-cy="searchField"
            :placeholder="$t('search_placeholder')"
            @keydown.enter="getDetails"
            v-model="search"
            type="text"
          />
          <span
            tabindex="0"
            @click="getDetails"
            class="search-icon absolute right-0 inline-flex items-center justify-center focus:shadow-outline focus:outline-none rouded-sm top-half transform -translate-y-1/2 box-border cursor-pointer"
            data-cy="goSearch"
          >
            <icon-search />
          </span>
        </div>
      </div>
      <hr class="my-6 border-grey-40 hidden md:block" />
      <template v-if="!isLoading">
        <div
          v-if="orders.length && summary"
          class="flex flex-col md:flex-row space-x-0 md:space-x-6 items-start"
        >
          <orders-list class="order-3 md:order-1 w-full md:w-8/12 lg:w-9/12" :orders="orders" />
          <hr class="order-2 border-grey-30 my-6 md:hidden w-full" />
          <total-info
            v-if="summary && summary.count"
            :info="summary"
            class="order-1 md:order-3 w-full md:w-4/12 lg:w-3/12"
          />
        </div>
        <no-orders v-else />
      </template>
      <list-loader v-else />
    </div>
  </div>
</template>

<script>
import { trackDateRangeChanges } from '../services/gtm'
import TotalInfo from '../components/TotalInfo'
import OrdersList from '../components/OrdersList'
import OrderFilters from '../components/OrderFilters'
import NoOrders from '../components/NoOrders'
import IconSearch from '../icons/IconSearch'
import DatePicker from '../components/DatePicker'
import ListLoader from '../components/ListLoader'
import { GET_ORDER_HISTORY, GET_SUMMARY } from '../store/actions.types'

export default {
  name: 'Home',
  title() {
    return this.$t('order_history')
  },
  components: {
    TotalInfo,
    OrdersList,
    OrderFilters,
    IconSearch,
    DatePicker,
    NoOrders,
    ListLoader,
  },
  data() {
    return {
      search: '',
    }
  },
  computed: {
    orders() {
      return this.$store.state.orders
    },
    summary() {
      return this.$store.state.summary
    },
    isLoading() {
      return this.$store.state.isLoading
    },
    dateFilter() {
      return this.$store.getters.dateFilter
    },
  },
  async mounted() {
    await this.$store.dispatch(GET_ORDER_HISTORY)
    await this.$store.dispatch(GET_SUMMARY)
    trackDateRangeChanges(this.dateFilter)
  },
  methods: {
    getDetails() {
      if (!this.search.length) return
      this.$router.push({
        name: 'OrderDetails',
        params: { id: this.search },
      })
    },
  },
}
</script>
<style scoped>
.search {
  padding-right: 36px;
  height: 40px;
}

.search-icon {
  width: 36px;
  height: 36px;
}
</style>
