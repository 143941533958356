<template>
  <div class="relative">
    <button
      tabindex="0"
      class="w-full border border-solid flex px-4 py-2 rounded-2xl cursor-pointer items-center space-x-2 max-h-32 justify-center md:justify-start"
      :class="[
        {
          'bg-grey-25 border-grey-40': isOpen,
        },
        hasFilters ? 'bg-blue-100 border-blue-100' : 'bg-white border-grey-40',
      ]"
      @click="isOpen = !isOpen"
    >
      <span
        class="leading-none font-bold text-sm inline-flex items-center"
        :class="{ 'text-white': !isOpen && hasFilters }"
        data-cy="filters"
      >
        <icon-filters class="hidden mr-2 sm:block" />
        <span>
          {{ $t('filters') }}
        </span>
        <span class="ml-2" :class="{ 'text-white': !isOpen }" v-if="hasFilters">
          ({{ hasFilters }})
        </span>
      </span>
    </button>
    <fade-transition>
      <div @click="isOpen = false" v-if="isOpen && isMobile" class="fixed inset-0 z-10">
        <div class="absolute inset-0 bg-grey-100 opacity-50" />
      </div>
    </fade-transition>
    <component :is="transition">
      <div
        v-if="isOpen"
        class="filter-dropdown bg-white p-4 shadow-md z-10 rounded-sm mt-2"
        :class="[isMobile ? 'fixed w-full bottom-0 left-0' : 'absolute']"
        @keyup.esc="isOpen = false"
      >
        <p class="relative">
          <span class="font-bold">{{ $t('filter_by') }}</span>
          <button @click="isOpen = false" class="absolute top-0 right-0">
            <svg
              class="w-6 h-6 text-blue-100"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </p>
        <p class="mt-6">{{ $t('order_status') }}</p>
        <div class="flex space-x-2 mt-2" data-cy="orderStatusFilter">
          <dropdown-pill
            v-for="status in orderStatusFilters"
            :key="status"
            v-model="filters.orderStatus"
            :data="status"
          >
            {{ $t(status.toLowerCase()) }}
          </dropdown-pill>
        </div>
        <p class="mt-6">{{ $t('payment_type') }}</p>
        <div class="flex space-x-2 mt-2" data-cy="paymentTypeFilter">
          <dropdown-pill
            v-for="type in paymentTypeFilters"
            :key="type"
            v-model="filters.paymentType"
            :data="type"
          >
            {{ $t(type.toLowerCase()) }}
          </dropdown-pill>
        </div>
        <p class="mt-6">{{ $t('service_type') }}</p>
        <div class="flex space-x-2 mt-2" data-cy="serviceTypeFilter">
          <dropdown-pill
            v-for="type in serviceTypeFilters"
            :key="type"
            v-model="filters.serviceType"
            :data="type"
          >
            {{ $t(type.toLowerCase()) }}
          </dropdown-pill>
        </div>
        <div class="mt-6 flex justify-end space-x-2">
          <pc-button @click="clearFilters" type="ghost" data-cy="clearFilters">
            {{ $t('clear') }}
          </pc-button>
          <pc-button @blur="isOpen = false" @click="applyFilters" data-cy="applyFilters">
            {{ $t('apply') }}
          </pc-button>
        </div>
      </div>
    </component>
  </div>
</template>

<script>
import IconFilters from '../icons/IconFilters'
import DropdownPill from '../components/DropdownPill'
import {
  PcButton,
  isMobile,
  clickOutside,
  FadeTransition,
  SlideYDownTransition,
} from '@je-pc/ui-components'
import { SET_ORDER_FILTERS } from '../store/mutation.types'
import { orderStatusFilters, paymentTypeFilters, serviceTypeFilters } from '../store/helpers'
import { GET_ORDER_HISTORY, GET_SUMMARY } from '../store/actions.types'
import { trackFiltersChanges } from '../services/gtm'

export default {
  components: {
    IconFilters,
    DropdownPill,
    PcButton,
    FadeTransition,
    SlideYDownTransition,
  },
  mixins: [isMobile, clickOutside],
  data() {
    return {
      orderStatusFilters,
      paymentTypeFilters,
      serviceTypeFilters,
      filters: {
        orderStatus: [],
        paymentType: [],
        serviceType: [],
      },
    }
  },
  computed: {
    transition() {
      return this.isMobile ? 'SlideYDownTransition' : 'FadeTransition'
    },
    filtersState() {
      return this.$store.state.filters.orderFilters
    },
    hasFilters() {
      return (
        this.filtersState.orderStatus.length +
        this.filtersState.paymentType.length +
        this.filtersState.serviceType.length
      )
    },
  },
  watch: {
    isOpen() {
      this.filters = this.filtersState
    },
  },
  methods: {
    async applyFilters() {
      this.$store.commit(SET_ORDER_FILTERS, this.filters)
      this.isOpen = false
      await this.$store.dispatch(GET_ORDER_HISTORY)
      await this.$store.dispatch(GET_SUMMARY)
      trackFiltersChanges(this.filters)
    },
    async clearFilters() {
      this.$store.commit(SET_ORDER_FILTERS, {
        orderStatus: [],
        paymentType: [],
        serviceType: [],
      })
      this.isOpen = false
      await this.$store.dispatch(GET_ORDER_HISTORY)
      await this.$store.dispatch(GET_SUMMARY)
    },
  },
}
</script>

<style scoped>
@media screen and (min-width: 640px) {
  .filter-dropdown {
    width: 320px;
  }
}
</style>
